import { OwnRecipeLayerIngredientsModel } from '../../../own-recipes/models/own-recipe-layer-ingredients/own-recipe-layer-ingredients.model';
import { AddMixtureLayerFormModel } from '../../models/add-mixture-layer-form/add-mixture-layer-form.model';
import { AddMixtureLayerIngredientsFormModel } from '../../models/add-mixture-layer-ingredients-data/add-mixture-layer-ingredients-data.model';
import { MixtureLayerWithIngredientsModel } from '../../models/mixture-layer-with-ingredients/mixture-layer-with-ingredients.model';
import { MixtureIngredientModel } from '../../models/mixture-ingredient/mixture-ingredient.model';
import { RecipeLayerForMixingModel } from '../../../recipes/models/recipe-layer-for-mixing/recipe-layer-for-mixing-model.model';
import { RecipeLayerIngredientForMixingModel } from '../../../recipes/models/recipe-layer-ingredient-for-mixing/recipe-layer-ingredient-for-mixing.model';
import { OwnRecipeIngredientModel } from '../../../own-recipes/models/own-recipe-ingredient/own-recipe-ingredient.model';
import { FixMixturesListItemDetailsIngredientModel } from '../../../fixes/models/fix-mixtures-list-item-details-ingredient/fix-mixtures-list-item-details-ingredient.model';
import { AddonIngredientListItemModel } from '../../../addons/models/addon-ingredient-list-item/addon-ingredient-list-item.model';
import { AlternativeIngredientModel } from '../../../shared/models/alternative-ingredient/alternative-ingredient.model';

export class CreateMixtureLayersUtil {
  public static createAddMixtureLayers(
    layers: Array<MixtureLayerWithIngredientsModel | RecipeLayerForMixingModel>
  ): Array<AddMixtureLayerFormModel> {
    return layers.map((layer: MixtureLayerWithIngredientsModel | RecipeLayerForMixingModel) => {
      return {
        ingredients: CreateMixtureLayersUtil.createAddMixtureLayerIngredients(layer.ingredients),
        layerNumber: layer.layerNumber,
      };
    });
  }

  public static createAddMixtureLayersFromOwnRecipeLayerIngredients(
    layers: Array<OwnRecipeLayerIngredientsModel>
  ): Array<AddMixtureLayerFormModel> {
    return layers.map((layer: OwnRecipeLayerIngredientsModel) => {
      return {
        ingredients: CreateMixtureLayersUtil.createAddMixtureLayerIngredientsFromOwnRecipeIngredient(layer.ingredients),
        layerNumber: layer.layerNumber,
      };
    });
  }

  public static createAddMixtureLayersFromIngredientsListModel(
    ingredients: Array<FixMixturesListItemDetailsIngredientModel | AddonIngredientListItemModel>
  ): Array<AddMixtureLayerFormModel> {
    return [
      {
        ingredients: CreateMixtureLayersUtil.createAddMixtureLayerIngredientsFromFixMixturesListItemDetailsIngredientModel(ingredients),
        layerNumber: 1,
      },
    ];
  }

  private static createAddMixtureLayerIngredients(
    ingredients: Array<RecipeLayerIngredientForMixingModel | MixtureIngredientModel>
  ): Array<AddMixtureLayerIngredientsFormModel> {
    return ingredients.map((ingredient: RecipeLayerIngredientForMixingModel | MixtureIngredientModel) => {
      return {
        id: ingredient.id,
        recipeAmount: CreateMixtureLayersUtil.createRecipeAmount(ingredient),
        dosedAmount: 0,
        leftAmount: 0,
        alternatives: CreateMixtureLayersUtil.getAlternativeIngredientValues(ingredient.alternatives),
      };
    });
  }

  private static createRecipeAmount(ingredient: RecipeLayerIngredientForMixingModel | MixtureIngredientModel): string {
    if (ingredient.amountRaw.max === ingredient.amountRaw.min) {
      return ingredient.amountRaw.value.toString();
    }

    return `${ingredient.amountRaw.min} - ${ingredient.amountRaw.max}`;
  }

  private static createAddMixtureLayerIngredientsFromOwnRecipeIngredient(
    ingredients: Array<OwnRecipeIngredientModel>
  ): Array<AddMixtureLayerIngredientsFormModel> {
    return ingredients.map((ingredient: OwnRecipeIngredientModel) => {
      const id: number = ingredient.id;
      const recipeAmount: string = ingredient.amount.toString();

      return {
        id,
        recipeAmount,
        dosedAmount: 0,
        leftAmount: 0,
        alternatives: [],
      };
    });
  }

  private static createAddMixtureLayerIngredientsFromFixMixturesListItemDetailsIngredientModel(
    ingredients: Array<FixMixturesListItemDetailsIngredientModel | AddonIngredientListItemModel>
  ): Array<AddMixtureLayerIngredientsFormModel> {
    return ingredients.map((ingredient: FixMixturesListItemDetailsIngredientModel | AddonIngredientListItemModel) => {
      const id: number = ingredient.id;
      const recipeAmount: string = ingredient.amount.toString();

      return {
        id,
        recipeAmount,
        dosedAmount: 0,
        leftAmount: 0,
        alternatives: [],
      };
    });
  }

  private static getAlternativeIngredientValues(alternatives: Array<AlternativeIngredientModel>): Array<number> {
    return alternatives.map((alternative: { label: string; value: number }) => alternative.value);
  }
}
